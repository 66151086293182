body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-container {
  position: absolute;
  z-index: 1;
}

.header-second-container{
  position: absolute;
  margin-top: 12vh;
  margin-left: 10px;
  z-index: 1;
}

.header-second-container a{
  width: auto;
  text-decoration: none;
}

.header-container img {
  height: 10vh;
  width: auto;
  margin: 10px;
}

.Loader {
  position: absolute;
  z-index: 1;
  height: 50%;
  width: 50%;
  top: 50%;
  left: 25%;
  font-size: 20px;
  text-align: center;
  color: white;

}

#block_container {
  text-align: center;
}
#block_container > div {
  display: inline-block;
  vertical-align: middle;
}

.button {
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #4CAF50; /* Green */
  color: white;
}